import React from 'react';

import BoraPage from '../components/BoraPage';
import BoraBlaast from '../components/BoraBlaast';

export default () => (
  <BoraPage pageName="404" pageTitle="404: Oops, Not found!" slug="404">
    <BoraBlaast/>
  </BoraPage>
);
